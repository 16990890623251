/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

import 'admin-lte/plugins/fontawesome-free/css/all.min.css';
import 'admin-lte/dist/css/adminlte.min.css';
import 'select2/dist/css/select2.min.css';
import 'jquery-editable-select/dist/jquery-editable-select.min.css';
import 'tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.min.css';
import 'admin-lte/plugins/chart.js/Chart.min.css';

import jQuery from 'admin-lte/plugins/jquery/jquery.min.js';
window.$ = jQuery;
window.jQuery = jQuery;
import "admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js";
import "select2/dist/js/select2.js";
import "select2/dist/js/i18n/ja.js";
import "jquery-editable-select/dist/jquery-editable-select.min.js";
import bsCustomFileInput from "bs-custom-file-input/dist/bs-custom-file-input.js";
window.bsCustomFileInput = bsCustomFileInput;
import "moment/moment.js";
import "tempusdominus-bootstrap-4/build/js/tempusdominus-bootstrap-4.min.js";
import 'admin-lte/plugins/chart.js/Chart.min.js';
require('admin-lte/dist/js/adminlte.min.js');
// ------ Theme itself ------
require('../public/js/custom.js');
require('../public/js/chart/chart-area.js');